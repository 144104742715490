const Promise = require('promise');
const map = require('lodash.map');
const dialog = require('../../dialog');
const minicart = require('../../minicart');
const page = require('../../page');
const productUtil = require('./productUtil');
const util = require('../../util');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
function addItemToCart(form) {
    const $form = $(form);
    let url;
    const $qty = $form.find('input[name="Quantity"], select[name="Quantity"]');

    if ($qty.length === 0 || Number.isNaN($qty.val()) || parseInt($qty.val(), 10) === 0 || Number($qty.val()) < 0) {
        $qty.val('1');
    }

    setTimeout(() => {
        $form.find('button[type="submit"]').css('pointer-events', 'all');
        // Make sure to enable Add All button for Sets
        if ($('#add-all-to-cart').length) {
            $('#add-all-to-cart').css('pointer-events', 'all');
        }
    }, 1000);
    if ($('#add-all-to-cart').length > 0 && $('#add-all-to-cart').hasClass('added-to-cart')) {
        const pSetimgUrl = $('.pdpMain').find('#showPSetImg').val();
        const pSetName = $('.pdpMain').find('#showPSetName').val();
        const itemQty = $('.product-set-item form').not('.suppressed').find('input[name="Quantity"]');
        let pSetQtyVal = 0;
        itemQty.each((index, element) => {
            pSetQtyVal += parseInt($(element).val(), 10);
        });

        url = util.appendParamsToUrl(Urls.addProduct, {
            pSetAdded: true, pSetimgUrl, pSetName, pSetQty: pSetQtyVal,
        });
    } else {
        url = Urls.addProduct;
    }
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(url),
        data: $form.serialize(),
    })).then((response) => {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            return response;
        }
    });
}

/**
 * @description Handler to handle the add to cart event
 */
const disabledAddToCart = (e) => {
    e.preventDefault();
    $(e.currentTarget).parents('form').find('.availability-novariation').addClass('error-msg');
};
const addToCart = (e) => {
    e.preventDefault();

    // check product option for fee
    const formID = $(e.currentTarget).closest('form').attr('id');
    productUtil.checkForOptionProductFee(formID);

    const $form = $(e.currentTarget).closest('form');

    // add form validation for personalization and validate Pick 'n Choose
    if (!$form.valid() || !productUtil.validatePickNChoose()) {
        // reveal any hidden fields
        $form.find('a.show-more').click();
        return;
    }

    $(e.currentTarget).css('pointer-events', 'none');

    addItemToCart($form).then((response) => {
        const $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            const params = util.getQueryStringParams(window.location);

            // redirect to referring source or refresh
            if (params.source === 'cart') {
                page.redirect(Urls.cartShow);
            } else {
                // wishlist or registry
                page.refresh();
            }
        } else {
            // Always close open dialogs and show the Add to Cart modal
            dialog.close();
            minicart.showmodal(response);
        }
    }, () => {
        productUtil.showLimitErrorDialog();
    });
};

/**
 * @description Handler to handle the add all items to cart event
 */
const addAllToCart = (e) => {
    e.preventDefault();
    const $productForms = $('#product-set-list').find('form').not('.suppressed').toArray();
    $(e.currentTarget).css('pointer-events', 'none').addClass('added-to-cart');

    Promise.all(map($productForms, addItemToCart)).then((responses) => {
        dialog.close();
        // show the final response only, which would include all the other items
        minicart.showmodal(responses[responses.length - 1]);
        $('#add-all-to-cart').removeClass('added-to-cart');
    });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function addToCartHandlers() {
    $('.product-detail, .svc-result-items').on('click', '.add-to-cart-disabled', disabledAddToCart);
    $('.product-detail, .svc-result-items').on('click', '.add-to-cart', addToCart);
    $('#add-all-to-cart').on('click', addAllToCart);
};
